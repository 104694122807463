#analytics{
    background-image: url("../images/analytics.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


#banner-analytics {

    width: 100vw;
    height: 50vh;
    object-fit: cover;
}




.container-analytics {
    position: relative;
}