.about-us {
    border-width: 1px;
    border-style: solid;
    width: 70%;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 10%;
    

    border-radius: 10px;
    padding: 25px;

    background-color: #18487a;
    text-align: center;

    /* opacity: 0.5; */
    color: white;



}

.outer-home {
    /* height: 100%; */
    background-image: url("../images/pc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
}

.services {
    margin-left: 10%;
    width: 70%;
    margin-bottom: 50px;
}

/* .first-image{
    background-image: url("../images/computer.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    margin-bottom: 1px;

}

.second-image {
    background-image: url("../images/devops.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    margin-bottom: 1px;

}

.third-image {
    background-image: url("../images/pc.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 70%;
    margin-bottom: 1px;

} */
.reliable-partner {
    background-image: url("../images/partner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    height: 50%;
    float: left;

}

.global-teams {

    background-image: url("../images/global_teams.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    height: 50%;
    float: left;

}

.strong-leadership {

    background-image: url("../images/leadership.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 50%;
        height: 50%;
        float: left;

}

.certifications {

    background-image: url("../images/certifications.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    height: 50%;
    float: left;

}

.h3-info 
    {
            color: "white"; padding: "10px"; margin-top:"10%"; background-color:"black"; opacity:"0.7";
    }



.banner-home {

    width: 100vw;
    height: 60vh;
    object-fit: cover;
}




.container-home {
    position: relative;
}

.topleft-home {
    position: absolute;
    top: 25%;
    left: 6%;
    font-size: 40px;
    color: black;
}