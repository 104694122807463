#banner-cloud {
    
    width: 100vw;
    height: 30vh;
    object-fit: cover;
}




.container-cloud {
    position: relative;
}

.topleft-cloud {
    position: absolute;
    top: 25%;
    left: 30%;
    font-size: 40px;
    color: white;
}