#banner-api {

    width: 100vw;
    height: 40vh;
    object-fit: cover;
}




.container-api {
    position: relative;
}

.topleft-api {
    position: absolute;
    top: 25%;
    left: 30%;
    font-size: 40px;
    color: white;
}


