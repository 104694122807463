.bg-primary {
    background-color: blue;

}


.me-auto {
    color: red;
}

.navbar-text {
    font-size: 18px;
}